import Aws from '@/images/logos/aws-logo.svg'
import Image from 'next/image'
import { ReactSVG } from 'react-svg'


export const AwsIcon = function ({ name, className, ...props }) {
  return (
    <ReactSVG
      src={Aws.src}
      className={className}
      {...props}
      beforeInjection={(svg) => {
        svg.classList.add('w-full', 'h-auto', 'inline-block', 'fill-current')
      }}
    />
  )
}


