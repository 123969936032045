import AwsWhite from '@/images/icons/Wordpress-Logo.svg'
import Image from 'next/image'
import { ReactSVG } from 'react-svg'


export const WpIcon = function ({ name, className, ...props }) {
  return (
    <ReactSVG
      src={AwsWhite.src}
      className={className}
      {...props}
      beforeInjection={(svg) => {
        svg.classList.add('w-full', 'h-auto', 'inline-block', 'fill-current')
      }}
    />
  )
}


