import G2Svg from '@/images/logos/g2.svg'
import { ReactSVG } from 'react-svg'

export const G2Icon = function ({ name, className, ...props }) {
  return (
    <ReactSVG
      src={G2Svg.src}
      className={className}
      {...props}
      beforeInjection={(svg) => {
        svg.classList.add('w-full', 'h-auto', 'inline-block', 'fill-current')
      }}
    />
  )
}
